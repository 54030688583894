<template>
  <!-- <div> -->
  <div>
    <AuthVisitorLoginModal
      :dialog-visible="showLoginModal"
      @closeModal="isLoginModalClosed"
      @onSetInCart="onSetInCart = $event"
      @onSetInMySubscription="setInMySubscription = $event"
      :set-in-cart="setInCart"
      :is-it-free="free"
      :inform-me-about-the-material="informMe"
    />
    <Alert
      :alert-visible="showAlert"
      :alert-data="alertData"
      @close="showAlert = false"
    />
    <InformMeDialog
      :openInformMeDialog="showInformMeDialog"
      :isCustomerLogIn="isLogin"
      :productId="productId"
      :productType="productType"
      @on-logIn="onSignIn"
      @on-success-message="showSuccessMessage"
      @on-close-informMe-dialog="(val) => (showInformMeDialog = val)"
    />
    <v-card
      :class="customCard === true ? 'related-card' : 'card'"
      :style="smAndDown && customCard ? 'width: 70%' : ''"
    >
      <img @click="showDetails" :src="courseData.poster" class="card__img" />
      <v-card-title @click="showDetails" class="card__title">
        {{ textLengthFilter(courseData.name, 25) }}
      </v-card-title>

      <div class="card__sub mb-3">
        <div>{{ courseData.videos_count }} فيديو</div>
        <div
          v-if="courseData.price > 0 && !courseData.hide_price"
          :class="immediatelySubscription ? 'reduced-courses' : 'price'"
        >
          {{ courseData.price + " ر.س" }}
          <br v-if="windowSize < 768" />
          <span
            v-if="
              courseData.old_price && courseData.old_price !== courseData.price
            "
            class="old-price"
            >{{ courseData.old_price + " ر.س" }}</span
          >
        </div>
        <div v-else-if="!courseData.hide_price" class="price">مجانية</div>
        <br />
      </div>
      <p
        v-if="
          courseData.does_has_vat === true && courseData.hide_price === false
        "
        :class="immediatelySubscription ? 'reduced-courses' : 'mr-4 tax'"
      >
        السعر شامل الضريبة :
        <br v-if="windowSize < 480" />
        {{ courseData.price_with_vat_show + " ر.س" }}
      </p>
      <p v-else-if="!reducedCoursesSection" class="mr-4 tax" />
      <div
        v-show="
          reducedCoursesSection && courseData.reduced_price_button_message
        "
        class="all-reduced-courses mb-2 mr-4 tax"
      >
        <span> {{ courseData.reduced_price_button_message }}</span>
      </div>

      <div
        v-if="courseData.tags && courseData.tags.length > 0"
        class="tags ma-2 mr-4"
      >
        <span
          :class="
            courseData.reduced_price_button_message
              ? 'without-description'
              : 'mt4'
          "
        >
          <v-chip
            class="ml-2 mb-2"
            v-for="tag in courseData.tags"
            :key="tag.id"
            :style="`background-color: ${tag.color}`"
            large
          >
            {{ tag.name }}
          </v-chip>
        </span>
      </div>
      <div class="ma-2 mr-4">
        <p class="text-12 font-weight-bold">متوفر الدفع بالتقسيط بواسطة :</p>
        <div class="d-flex my-2 w-full">
          <img src="~/assets/images/shared/tabby.svg" alt="tabby" class="payment-icon" />
          <img
            src="~/assets/images/shared/tamara.svg"
            alt="tamara"
            class="mr-2 tamara-icon payment-icon"
          />
        </div>
      </div>
      <v-card-actions
        class="card__btns"
        :class="{
          'without-description': courseData.tags && !courseData.tags.length > 0,
        }"
      >
        <v-btn
          v-if="showBtn"
          :loading="loading"
          @click="
            courseData.pay_button_title === 'مشترك'
              ? showDetails()
              : addToCart(course)
          "
          :class="`add-to-cart ${courseData.pay_button_title === 'أخبرني عند التوفر' ? 'inform-me' : ''}`"
          elevation="2"
        >
          <!-- :disabled="!courseData.pay_button_status" -->
          <img
            :src="`/images/cards/${
              courseData.pay_button_status
                ? courseData.pay_button_title === 'مشترك'
                  ? 'watch'
                  : courseData.pay_button_title === 'أخبرني عند التوفر'
                    ? 'notification'
                    : 'buy'
                : 'buy'
            }.svg`"
            alt="buy icon"
          />
          <small>
            {{ courseData.pay_button_title }}
          </small>
        </v-btn>
        <div
          class="buttons-wrapper"
          :class="{ 'd-flex align-center': smAndUp }"
        >
          <div v-if="reducedCoursesSection === false">
            <v-tooltip v-if="courseData.pay_button_title === 'في السلّة'" top>
              <template v-slot:activator="{ props }">
                <div class="pointer" v-bind="props">
                  <GiftBtn
                    v-if="
                      courseData.price > 0 || courseData.price === 'hide_price'
                    "
                    :is-btn-disabled="
                      courseData.pay_button_title === 'في السلّة'
                    "
                    product-type="course"
                    :product-data="course"
                    @onGift="onGift"
                  />
                </div>
              </template>
              <span v-if="courseData.pay_button_title === 'في السلّة'"
                >هذه المادة مضافة للسلة الخاصة بك ، يمكنك اكمال خطوات الإهداء في
                السلة .</span
              >
            </v-tooltip>
            <GiftBtn
              v-else-if="
                courseData.price > 0 || courseData.price === 'hide_price'
              "
              :is-btn-disabled="courseData.pay_button_title === 'في السلّة'"
              product-type="course"
              :product-data="course"
              @onGift="onGift"
              :check-is-related-course="isRelatedCourse"
            />
          </div>
          <FavoriteBtn :item="course" @onFavorite="onFavorite" />
        </div>
      </v-card-actions>
    </v-card>
  </div>
  <!-- </div> -->
</template>

<script setup>
import windowSize from "~/mixins/window-size";
import gtmEvents from "~/mixins/gtm-events";
import LoginModal from "~/components/auth/LoginModal.vue";
import FavoriteBtn from "~/components/course/FavoriteBtn.vue";
import GiftBtn from "~/components/course/GiftBtn";
import InformMeDialog from "~/components/course/InformMeDialog";
import Alert from "~/components/shared/Alert.vue";
import { useHomeStore } from "~/stores/HomeStore";
import { useCartStore } from "~/stores/CartStore";
import { useCourseStore } from "~/stores/CourseStore";
import { useAuthStore } from "~/stores/AuthStore";
import { useDisplay } from "vuetify";
import { ref } from "vue";

const emit = defineEmits(["onFavorite", "onAddToCart"]);

const props = defineProps({
  course: {
    type: Object,
    default: () => ({}),
  },
  isRelatedCourse: {
    type: Boolean,
    default: false,
  },
  customCard: {
    type: Boolean,
    default: false,
  },
  reducedCoursesSection: {
    type: Boolean,
    default: false,
  },
  isReducedCoursePage: {
    type: Boolean,
    default: false,
  },
  showBtn: {
    type: Boolean,
    default: true,
  },
});

const { smAndUp, smAndDown } = useDisplay();
const authStore = useAuthStore();
const courseStore = useCourseStore();
const homeStore = useHomeStore();
const cartStore = useCartStore();
const { trackingPurchaseJourney } = useTrackingEvents();

// Destructure the filter function from the composable
const { textLengthFilter } = useFilters();

const showLoginModal = ref(false);
const showAlert = ref(false);
const alertData = ref({});
const setCourse = ref({});
const loading = ref(false);
const isInCart = ref(false);
const setInMySubscription = ref(false);
const free = ref(false);
const showInformMeDialog = ref(false);
const informMeAfterLogin = ref(false);
const modelId = ref("");
const modelType = ref("");
const onSetInCart = ref(false);
const setInCart = ref(false);
const cartItems = ref([]);

const courseData = ref(props.course || {});

const router = useRouter();
const route = useRoute();

// initialize cookies
const productIdCookie = useCookie("productId", {
  expires: new Date(Date.now() + 60 * 60 * 1000), // 1 hour from now
});
const productTypeCookie = useCookie("productType", {
  expires: new Date(Date.now() + 60 * 60 * 1000), // 1 hour from now
});

const isLogin = computed(() => authStore.auth.isLoggedIn);
const isFree = computed(() => props.course.price == 0);
const reducedCourses = computed(
  () => props.course.has_reduced_price || props.course.is_instant_subscription,
);
const immediatelySubscription = computed(
  () => reducedCourses.value && props.course.pay_button_title === "اشتراك فوري",
);
const user = computed(() => authStore.auth.user);

const informMe = computed(
  () => props.course.pay_button_title === "أخبرني عند التوفر",
);
const productId = computed(() => {
  return modelId.value ? modelId.value : productIdCookie.value;
});

const productType = computed(() => {
  return modelType.value ? modelType.value : productTypeCookie.value;
});

watch(
  () => setInMySubscription.value,
  (newValue) => {
    if (newValue && free.value) {
      setTimeout(() => {
        if (isLogin.value) {
          addToSubscriptions();
        }
      }, 2000);
      setInMySubscription.value = false;
    }
  },
  { deep: true },
);

watch(
  () => onSetInCart.value,
  (newValue) => {
    if (newValue && setCourse.value) {
      setTimeout(() => {
        if (isLogin.value) {
          checkIsInCart(setCourse.value);
        }
      }, 2000);
      onSetInCart.value = false;
    }
  },
  { deep: true },
);

watch(
  () => props.course,
  (newVal) => {
    courseData.value = newVal;
  },
  { deep: true },
);

watch(
  () => authStore.informMeWhenMaterialBeAvailable,
  (newValue) => {
    if (
      newValue == true &&
      informMeAfterLogin.value &&
      route.name === "materials" &&
      isLogin.value
    ) {
      setTimeout(() => {
        showInformMeDialog.value = true;
        informMeAfterLogin.value = false;
      }, 2000);
    }
  },
  { deep: true },
);

const getVideosLength = (num) => {
  if (num === 1) {
    return `${num} فيديو `;
  } else if (num === 2) {
    return `${num} فيديوهين`;
  } else if (num > 2 && num <= 10) {
    return `${num} فيديوهات`;
  } else if (num > 10) {
    return `${num} فيديو`;
  } else {
    return ``;
  }
};

const btnName = () => {
  if (isFree.value) {
    return windowSize.value > 768 ? "اشتراك فوري" : "اشترك";
  } else {
    return windowSize.value > 768 ? "اشترك الآن" : "اشترك";
  }
};

const showDetails = () => {
  navigateTo(`/materials/${props.course.slug}`);
};

const addToCart = async (course) => {
  if ((isFree.value && course.price !== "hide_price") || reducedCourses.value) {
    loading.value = true;
    if (authStore.auth.isLoggedIn) {
      addToSubscriptions();
    } else {
      setTimeout(() => {
        loading.value = false;
      }, 500);
      if (localStorage) {
        const ref = useCookie("ref");
        const promo = useCookie("promo");

        let coursesInLocalStorage =
          JSON.parse(localStorage.getItem("itemsInLocalStorage")) || [];
        const itemData = {
          id: courseData.value.id,
          type: "course",
          ref: ref.value,
          promo_code: promo.value,
        };

        if (
          !coursesInLocalStorage.some(
            (item) => item.id === course.id && item.type === "course",
          )
        ) {
          coursesInLocalStorage.push(itemData);
        }
        localStorage.setItem(
          "itemsInLocalStorage",
          JSON.stringify(coursesInLocalStorage),
        );
        if (!cartStore.cartList) {
          cartStore.cartList = [];
        }

        cartStore.cartList.push(course);

        setTimeout(() => {
          loading.value = false;
        }, 500);

        courseData.value.pay_button_title = "في السلّة";
        courseData.value.pay_button_status = false;
        emit("onAddToCart", courseData.value.id);

        setAlertData({
          message: "تمت إضافة المنتج للسلة بنجاح",
          success: true,
        });
      }
    }
  } else {
    !informMe.value ? (loading.value = true) : (loading.value = false);
    if (informMe.value) {
      productIdCookie.value = course.id;
      productTypeCookie.value = course.model_type;
      showInformMeDialog.value = true;
      informMeAfterLogin.value = true;
    }
    if (authStore.auth.isLoggedIn) {
      if (informMe.value) {
        modelId.value = course.id;
        modelType.value = course.model_type;
        showInformMeDialog.value = true;
        informMeAfterLogin.value = true;
      } else await checkIsInCart(course);
    } else {
      if (localStorage && !informMe.value) {
        const ref = useCookie("ref");
        const promo = useCookie("promo");

        let coursesInLocalStorage =
          JSON.parse(localStorage.getItem("itemsInLocalStorage")) || [];
        const itemData = {
          id: courseData.value.id,
          type: "course",
          ref: ref.value,
          promo_code: promo.value,
        };

        if (
          !coursesInLocalStorage.some(
            (item) => item.id === course.id && item.type === "course",
          )
        ) {
          coursesInLocalStorage.push(itemData);
        }
        localStorage.setItem(
          "itemsInLocalStorage",
          JSON.stringify(coursesInLocalStorage),
        );
        if (!cartStore.cartList) {
          cartStore.cartList = [];
        }

        cartStore.cartList.push(course);

        setTimeout(() => {
          loading.value = false;
        }, 500);

        courseData.value.pay_button_title = "في السلّة";
        courseData.value.pay_button_status = false;

        emit("onAddToCart", courseData.value.id);

        setAlertData({
          message: "تمت إضافة المنتج للسلة بنجاح",
          success: true,
        });
      }
    }
  }
};

const addToSubscriptions = async () => {
  loading.value = true;
  const formData = new FormData();
  formData.append("id", props.course.id);
  formData.append("type", props.course.model_type || props.course.type);
  await courseStore.addToSubscriptions(formData).then((resData) => {
    setTimeout(() => {
      loading.value = false;
      setAlertData(resData);
    }, 2000);
  });
  if (props.isReducedCoursePage) {
    const filters = {
      search_text: null,
      all_reduced_courses: true,
      sort: null,
      is_purchasable: null,
      category: "",
      per_page: 10,
      page: 1,
      orderBy: "created_at",
    };
    await courseStore.fetch(filters);
  } else await homeStore.fetchHome();
};

const isLoginModalClosed = (payload) => {
  if (payload.value) {
    showLoginModal.value = false;
    if (payload.showSignup) {
      signup();
    }
  }
  setInCart.value = false;
};

const signup = () => {
  navigateTo("/auth/signup");
};

const checkIsInCart = async (course) => {
  if (!cartStore.cartList) {
    await cartStore.getCartDetails();
  }

  cartItems.value = cartStore.cartList;
  isInCart.value = cartItems.value.find((ele) => ele.id === course.id);
  if (!isInCart.value) {
    const formData = new FormData();
    formData.append(
      "product_id",
      course.is_special_id ? course.special_id : course.id,
    );

    formData.append("product_type", "course");

    const ref = useCookie("ref");
    const promo = useCookie("promo");
    if (ref.value) {
      formData.append("ref", ref.value);
    }
    if (promo.value) {
      formData.append("promo_code", promo.value);
    }
    await cartStore
      .addItemToCart(formData)
      .then(async (res) => {
        if (res.status_code !== 307) {
          setAlertData({
            message: res.message,
            success: res.success,
          });

          courseStore.changeItemStatusAfterAddedToCart(course.id);
          if (route.name === "courses") {
            onAddToCart(course.id);
          } else {
            homeStore.fetchHome();
          }
          // Fire AddToCart event
          trackingPurchaseJourney("viewItemSkipped", user.value, course);
          trackingPurchaseJourney("AddToCart", user.value, course);
        } else {
          addToSubscriptions();
        }
        loading.value = false;
      })
      .catch((err) => {
        loading.value = false;
        console.error(err);
      });
  } else {
    loading.value = false;
    setAlertData({
      message: "تم إضافة هذا المنتج إلى السلة مسبقاً",
      success: false,
    });
  }

  setTimeout(() => {
    loading.value = false;
  }, 500);
};
const onFavorite = () => {
  emit("onFavorite", { value: true });
};

const onAddToCart = (courseId) => {
  emit("onAddToCart", courseId);
};

const onGift = (responseData) => {
  setAlertData(responseData);
};

const onSignIn = () => {
  showLoginModal.value = true;
  setTimeout(() => {
    showInformMeDialog.value = false;
  }, 500);
};
const showSuccessMessage = (data) => {
  setAlertData({
    message: data.message,
    success: data.success,
  });
};
const setAlertData = (data) => {
  alertData.value = data;
  showAlert.value = true;
};

onMounted(() => {
  if (!isLogin.value) {
    setTimeout(() => {
      const coursesInLocalStorage =
        JSON.parse(localStorage.getItem("itemsInLocalStorage")) || [];
      if (
        cartStore.cartList &&
        cartStore.cartList.length !== coursesInLocalStorage.length
      ) {
        const found = coursesInLocalStorage.find(
          (courseInLS) => courseInLS.id === courseData.value.id,
        );
        if (found) {
          courseData.value.pay_button_title = "في السلّة";
          courseData.value.pay_button_status = false;
          if (!cartStore.cartList) {
            cartStore.cartList = [];
          }

          cartStore.cartList.push(courseData.value);
        }
      }
    }, 500);
  }
});
</script>

<style lang="scss" scoped>
.related-card {
  min-height: 150px !important;
  .card__img {
    height: 170px !important;
  }
  .card__title {
    min-height: 0px !important;
  }
  // .card__btns {
  //   margin-top: 0px !important;
  // }
  .tax {
    min-height: 0px !important;
  }
}
.card,
.related-card,
.related-card-for-mobile {
  min-height: 490px;
  border-radius: 30px;
  * {
    word-break: break-word !important;
  }
  .card__img {
    cursor: pointer;
    height: 200px;
    width: 100%;
    margin: 0 0 11px;
    margin: 0;
  }
  .card__title {
    cursor: pointer;
    display: flex;
    color: #000;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.04;
    min-height: 78px;
  }
  .tax {
    font-size: 16px;
    font-weight: bold;
    min-height: 15px;
  }
  .card__sub {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    max-height: 30px;
    div {
      margin: 0px 15px 0 15px;
    }
  }
  .all-reduced-courses {
    padding: 0px 10px 0px 10px;
    margin-bottom: -10%;
    span {
      font-size: 12px;
      font-weight: bold;
      color: #8e6ba1;
      display: block;
      height: 46.7px;
    }
  }
  .price {
    position: relative;
    margin-right: 30px !important;
    &:before {
      content: "";
      width: 7px;
      height: 8px;
      margin: 0px;
      background-color: #bcbcbc;
      border-radius: 50%;
      position: absolute;
      right: -21px;
      top: 9px;
    }
    .old-price {
      font-size: 10px;
      color: #fa9237;
      text-decoration: line-through;
      margin-right: 10px;
    }
  }
  .reduced-courses {
    color: #8e6ba1;
    text-decoration: line-through;
    margin-right: 10px;
  }
  .card__btns {
    display: flex;
    justify-content: space-between;
    // margin-top: 0px;
    .add-to-cart {
      padding: 24px 20px;
      border-radius: 18px;
      background-color: #f4f4f4;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      img {
        margin-left: 5px;
      }
    }
    .inform-me {
      background: #87599d;
      color: #fff;
    }
  }

  .without-description {
    margin-top: 60px;
  }
  .card__des {
    font-size: 15px;
    font-weight: normal;
    line-height: 1.6;
    text-align: right;
    color: #000;
    min-height: 100px;
  }
  .card .card__sub {
    height: 40px !important;
    .all-reduced-courses {
      span {
        font-size: 12px;
        color: #8e6ba1 !important;
      }
    }
  }
}

@media (max-width: 1263px) {
  .card .without-description {
    margin-top: 85px;
  }
}
@media (max-width: 960px) {
  .related-card {
    max-width: 50% !important;
  }
  .related-card-for-mobile {
    min-height: 150px !important;
    display: flex !important;
    justify-content: space-around !important;
    .card__btns {
      display: flex !important;
      flex-direction: row !important;
      align-items: center !important;
    }
    .add-to-cart {
      padding: 24px 20px !important;
      border-radius: 18px !important;
      background-color: #f4f4f4;
      color: #000;
      font-size: 18px;
      font-weight: bold;
      img {
        margin-left: 5px;
      }
    }
    .buttons-wrapper {
      display: flex !important;
      gap: 10px !important;
      margin-top: 12px !important;
    }
  }
}

@media (max-width: 960px) {
  .card__btns {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // margin-top: 0px;
  }
  .buttons-wrapper {
    display: flex;
    gap: 10px;
    margin-top: 12px;
  }
  .card .without-description {
    margin-top: 97px;
  }
}

@media (max-width: 745px) {
  .card {
    .price {
      margin-right: 20px !important;
      position: relative;
      &:before {
        content: "";
        width: 7px;
        height: 8px;
        margin: 0px;
        background-color: #bcbcbc;
        border-radius: 50%;
        position: absolute;
        right: -16px;
        top: 9px;
      }
      .old-price {
        font-size: 10px;
        margin-right: 0px;
        margin-bottom: -10px;
      }
    }
    .all-reduced-courses {
      margin-bottom: -12%;
    }
  }
}

@media (max-width: 600px) {
  .card,
  .related-card-for-mobile {
    min-height: 350px;
    border-radius: 18px;
    padding-bottom: 10px;

    .card__img {
      height: 131px;
    }
    // .card__btns {
    //   margin-top: 0px !important;
    // }
    .without-description {
      margin-top: 59px !important;
    }
    .v-card__actions {
      padding-bottom: 2px;
    }
    .card__title {
      font-size: 15px;
    }
    .tax {
      font-size: 12px;
    }
    .card__sub {
      align-items: flex-start;
      font-size: 12px;
      min-height: 36px;
      div {
        margin: 0px 10px 0 5px;
      }
      .price {
        margin-right: 20px !important;
        &:before {
          content: "";
          width: 7px;
          height: 8px;
          margin: 0px;
          background-color: #bcbcbc;
          border-radius: 50%;
          position: absolute;
          right: -15px;
          top: 6px;
        }
        .old-price {
          font-size: 10px;
          margin-right: 0px;
          margin-bottom: -10px;
        }
      }
    }
    .all-reduced-courses {
      margin-bottom: -18%;
      span {
        font-size: 12px;
        color: #8e6ba1;
      }
    }
    .card__btns {
      // margin-top: 0px;
      .add-to-cart {
        display: flex;
        justify-content: space-around;
        padding: 18px 5px;
        margin: 0px 0 0px 2px;
        border-radius: 10px;
        font-size: 12px;
        min-width: 70%;
        img {
          margin-left: 2px;
        }
      }
    }
    .card__des {
      font-size: 10px;
      padding: 12px 12px 12px;
    }
  }
}

@media (max-width: 550px) {
  .related-card {
    max-width: 100% !important;
  }
  .related-card-for-mobile {
    // .add-to-cart {
    //   padding: 15px 10px !important;
    //   font-size: 14px;
    // }
  }
  .card .without-description {
    margin-top: 66px !important;
  }
}
@media (max-width: 400px) {
  .card .without-description {
    margin-top: 77px !important;
  }
}
@media (max-width: 375px) {
  .card {
    min-height: 350px;
    border-radius: 18px;
    padding-bottom: 10px;

    .card__img {
      height: 131px;
    }
    .card__btns {
      // margin-top: 0px !important;
    }
    .without-description {
      margin-top: 75px !important;
    }
    .v-card__actions {
      padding-bottom: 2px;
    }
    .card__title {
      font-size: 15px;
    }
    .tax {
      font-size: 12px;
    }
    .card__sub {
      align-items: flex-start;
      font-size: 12px;
      min-height: 36px;
      div {
        margin: 0px 10px 0 5px;
      }
      .price {
        margin-right: 20px !important;
        &:before {
          top: 5px;
          right: -18px;
        }
        .old-price {
          font-size: 10px;
          margin-right: 0px;
          margin-bottom: -10px;
        }
      }
      .all-reduced-courses {
        margin-bottom: -30%;
        span {
          font-size: 12px;
          color: #8e6ba1;
        }
      }
    }
    .card__btns {
      // margin-top: 0px;
      display: flex;
      .add-to-cart {
        justify-content: space-around;
        padding: 18px 5px;
        margin: 0px -4px 0px 2px;
        border-radius: 10px;
        font-size: 10px;
        min-width: 70%;
        img {
          margin-left: 2px;
        }
      }
    }
    .card__des {
      font-size: 10px;
      padding: 12px 12px 12px;
    }
  }
}
@media (max-width: 370px) {
  .card {
    .card__sub {
      div {
        margin: 0px 10px 0 5px;
      }
      .all-reduced-courses {
        span {
          font-size: 12px;
          color: #8e6ba1;
        }
      }
    }
    .without-description {
      margin-top: 80px !important;
    }
  }
  .favorite-btn {
    background-color: #f4f4f4 !important;
    padding: 14px 15px;
    font-size: 15px;
    border-radius: 10px;
  }
}
.tamara-icon {
  @media (max-width: 700px) {
   width:70px;
  }
}
.payment-icon {
  max-width:45%;
}
</style>
